import React from "react";
import Fade from 'react-reveal/Fade';

const Resume = ({ data }) => {
  if (data) {
    var skillmessage = data.skillmessage;
    var education = data.education.map(function (education) {
      return (
        <Fade bottom>
          <div key={education.school}>
            <h3>{education.school}</h3>
            <p className="info">
              {education.degree} <span>&bull;</span>
              <em className="date">{education.graduated}</em>
            </p>
            <p>{education.description}</p>
          </div>
        </Fade>
      );
    });
    var work = data.work.map(function (work) {
      let projectDescription = "";
      if (Array.isArray(work.description)) {
        projectDescription = work.description.map((item, index) => <li key={index}> {item} </li>);
        projectDescription = <ul>{projectDescription}</ul>;
      } else {
        projectDescription = <p>{work.description}</p>;
      }

      return (
        <Fade bottom>
          <div key={work.company}>
            <h3>{work.company}</h3>
            <p className="info">
              {work.title}
              <span>&bull;</span> <em className="date">{work.years}</em>
            </p>
            <div>{projectDescription}</div>
          </div>
        </Fade>
      );
    });
    var skills = data.skills.map(function (skills) {
      var className = "bar-expand " + skills.name.toLowerCase();
      return (
        <Fade bottom key={Math.random()}>
          <li key={skills.name}>
            <span style={{ width: skills.level }} className={className}></span>
            <em>{skills.name}</em>
          </li>
        </Fade>
      );
    });
  }

  return (
    <section id="resume">
      <div className="row education">
        <div className="three columns header-col">
          <Fade right>
            <h1>
              <span>Education</span>
            </h1>
          </Fade>
        </div>

        <div className="nine columns main-col">
          <div className="row item">
            <div className="twelve columns">{education}</div>
          </div>
        </div>
      </div>

      <div className="row work">
        <div className="three columns header-col">
          <Fade right>
            <h1>
              <span>Experience</span>
            </h1>
          </Fade>
        </div>

        <div className="nine columns main-col">{work}</div>
      </div>

      <div className="row skill">
        <div className="three columns header-col">
          <Fade right>
            <h1>
              <span>Skills</span>
            </h1>
          </Fade>
        </div>

        <div className="nine columns main-col">
          <p>{skillmessage}</p>

          <div className="bars">
            <ul className="skills">{skills}</ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Resume;
